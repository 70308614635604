import { useMemo } from 'react';
import { useAuthenticationResult } from './useAuthenticationResult';
import { getRoles } from './getRoles';

export function useRoles(): string[] {
    const [result] = useAuthenticationResult();

    const roles = useMemo(() => {
        return getRoles(result);
    }, [result]);

    return roles;
}
